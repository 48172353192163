import { SapFail } from './../../../models/sap-fail';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-sap',
  templateUrl: './sap.component.html',
  styleUrls: ['./sap.component.css']
})
export class SapComponent implements OnInit {

  data$!: string;
  mapHeader = {
    id: { display: '#', data: null },
    order_buy: { display: 'Orden', data: null },
    sap_code: { display: 'Pedido', data: null },
    state: { display: 'Etapa', data: null },
    message: { display: 'Problema', data: null },
    modified_at: { display: 'Modificado en', data: null },
    actions: {
      display: 'Acciones',
      sort: false,
      data: (data: any) => {
        return data.action ? `<button class="btn btn-link btn-edit">
                    <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="assets/img/bootstrap-icons.svg#arrow-repeat" />
                    </svg>
                </button>` :
          '';
      },
      edit: (data: any) => { this.edit(data); }
    },
  };
  @ViewChild('table') table!: AdminTableComponent<SapFail>;
  @ViewChild('content', { static: true }) content!: ElementRef;
  id: any;

  constructor(
    private readonly modalService: NgbModal,
    private readonly storeService: StoreService,
    private readonly toast: ToastService,
    public readonly responsiveService: ResponsiveLayoutService) {
  }

  ngOnInit(): void {
    this.data$ = 'sap/';
  }


  edit(ele: any) {
    this.id = ele.id;
    this.modalService.open(this.content, { centered: true });
  }

  save() {
    this.storeService.put_sap_retry(this.id).subscribe({
      next: (data) => {
        this.toast.show('Exito', 'Se ha solicitado correctamente', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
        this.table.reload();
        this.modalService.dismissAll();
      },
      error: (err) => {
        console.error('Error ocurrido:', err);
        if (err.error?.error) {
          console.error('Error específico:', err.error.error);
        }
        this.toast.show('Error', 'Ha ocurrido un error', { classname: 'bg-danger text-light toast-top-right', delay: 3000 });
      }
    });
  }
}
