import { Component, OnInit, ViewChildren, ElementRef, QueryList, ViewChild, NgZone } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { CommonService } from 'src/app/services/common.service';
import { Order } from 'src/app/models/order';
import { RutService } from 'src/app/services/rut.service';
import { User } from 'src/app/models/user';
import { StepService } from 'src/app/services/step.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { API_KEY } from 'src/app/shared/utils/recaptcha';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
//import { MapsAPILoader } from '@agm/core';
import { REGIONES_COMUNA_PAGO } from 'src/app/shared/utils/regiones';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {
  @ViewChildren('transactionFrom') transactionFrom?: QueryList<ElementRef>;
  @ViewChild('alertModal', { static: true }) alertModal?: ElementRef;
  @ViewChild('paymentModal', { static: true }) paymentModal?: ElementRef;
  @ViewChild('selectMap', { static: true }) selectMap?: ElementRef;
  form!: FormGroup;
  formAddress!: FormGroup;
  order?: Order;
  transaction = {
    url: '',
    token: ''
  };
  siteKey = API_KEY;
  geocoder = new google.maps.Geocoder();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly commomService: CommonService,
    private readonly storeService: StoreService,
    private readonly rutService: RutService,
    private readonly stepService: StepService,
    private readonly modalService: NgbModal,
    private readonly config: NgbModalConfig,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    public responsiveService: ResponsiveLayoutService,
    private readonly ngZone: NgZone
  ) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
  }

  regionesComunas = REGIONES_COMUNA_PAGO;
  get comunaByRegion() {
    const region = this.formAddress?.get('region')?.value;
    if (region) {
      return REGIONES_COMUNA_PAGO.find(r => r.nombreRegion == region)?.comunas;
    }
    return [];
  }

  checkAddressPart(address: any, part: any) {
    const check = address.find((x: any) => x.types.find((y: any) => y === part));
    return check;
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      const orderCode = params['id'];
      const orderId = params['orderId'];
      const isConfirmation = this.route.snapshot.url.some(segment => segment.path.includes('confirmacion'));
      const isCancellation = this.route.snapshot.url.some(segment => segment.path.includes('cancelar'));
  
      if (isConfirmation || isCancellation) {
        this.handlePaymentNotification(orderId, isConfirmation);
        return;
      }
  
      if (orderCode) {
        this.spinner.show();
        this.storeService.get_cotizacion(orderCode).subscribe({
          next: (e) => {
            if (e.cotizacion) {
              this.commomService.currentOrder = e;
              this.order = this.commomService.currentOrder;
              this.initForm();
              this.spinner.hide();
            } else {
              this.spinner.hide();
              this.cotizacionAlert();
            }
          }
        });
      } else {
        this.order = this.commomService.currentOrder;
        this.order.cotizacion = false;
        this.commomService.currentOrder = this.order;
      }
    });
  
    this.stepService.changeStep(5);
    this.initForm();
  }

  handlePaymentNotification(orderCode: string, isConfirmation: boolean) {
    this.spinner.show();
    const data = {
      "order_code": orderCode
    };
    const request = isConfirmation
        ? this.storeService.post_payment_confirmation(data)
        : this.storeService.post_payment_cancellation(data);

    request.subscribe({
        next: (response) => {
            this.spinner.hide();
            if (response.redirect_url) {
                location.href = response.redirect_url;
            } else {
                const title = isConfirmation ? 'Pago Confirmado' : 'Pago Cancelado';
                const description = isConfirmation
                    ? 'El pago se ha procesado exitosamente.'
                    : 'El pago ha sido cancelado. Si necesita más información, contáctenos.';
                this.showModal(title, description, () => {
                    this.router.navigate(['/']);
                });
            }
        },
        error: (err) => {
            this.spinner.hide();
            console.error('Error en el proceso de notificación', err);
            this.showModal('Error', 'Hubo un error al procesar la solicitud. Inténtelo más tarde.', () => {
                this.router.navigate(['/']);
            });
        }
    });
}

  showModal(title: string, description: string, onClose?: () => void) {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
  
    if (onClose) {
      modalRef.componentInstance.function = () => {
        this.modalService.dismissAll();
        onClose();
      };
    } else {
      modalRef.componentInstance.function = () => {
        this.modalService.dismissAll();
      };
    }
  }

  initForm() {
    this.form = this.fb.group({
      email: [this.order?.user?.email, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]],
      name: [this.order?.user?.name, [Validators.required]],
      giro: [this.order?.user?.giro, [Validators.required]],
      rut: [this.order?.user?.rut, [Validators.required]],
      address: [this.order?.user?.address, [Validators.required]],
      helper: [this.order?.user?.address],
      phone: [this.order?.user?.phone, [Validators.required]],
      check: ['', [this.checkTerms]],
    });
    this.formAddress = this.fb.group({
      street: ['', Validators.required],
      number: ['', Validators.required],
      comuna: ['', Validators.required],
      region: ['', Validators.required],
    });
  }

  checkTerms(control: FormControl) {
    return (control.value) ? null : { check: true };
  }

  checkRUT() {
    const rutControl = this.form?.get('rut');
    const cleanValue = this.rutService.rutClean(rutControl?.value);
    const valid = this.rutService.getRutChile(0, cleanValue);

    if (valid) {
      rutControl?.setErrors(null);
      rutControl?.setValue(this.rutService.rutFormat(cleanValue));
    } else {
      rutControl?.setErrors({ noEsIgual: true });
    }
  }

  private validate() {

    if (this.form?.get('address')?.status !== 'VALID') {
      this.showFacturacionAddressAlert();
    }

    if (this.form?.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });
      return false;
    }
    const data = this.form?.value;
    delete data.helper;
    this.commomService.setUserData(data as User);
    return true;
  }

  continuarPago() {
    this.flow();
  }

  webpay() {
    this.modalService.dismissAll();
    this.spinner.show();
    this.reCaptchaV3Service.execute('webpay').subscribe((token: string) => {
      const order = this.commomService.currentOrder;
      order.token = token;

      this.storeService.post_webpay_transaction(order).subscribe({
        next: (response) => {
          const form = this.transactionFrom?.first.nativeElement;
          this.transaction = { url: response.url, token: response.token };
          form.action = response.url;
          form[0].value = response.token;
          this.spinner.hide();
          form.submit();
        },
        error: () => {
          this.spinner.hide();
        }
      });
    }, error => {
      this.spinner.hide();
    });
  }

  flow() {
    this.modalService.dismissAll();
    this.spinner.show();

    this.reCaptchaV3Service.execute('flow').subscribe((token: string) => {
      const order = this.commomService.currentOrder;
      order.token = token;

      this.storeService.post_flow_transaction(order).subscribe({
        next: (response) => {
          location.href = `${response.url}?token=${response.token}`;
          this.spinner.hide();
        },
        error: (response) => {
          this.spinner.hide();
        }
      });
    }, error => {
      this.spinner.hide();
    });
  }


  gnet() {
    this.modalService.dismissAll();
    this.spinner.show();
  
    this.reCaptchaV3Service.execute('gnet').subscribe((token: string) => {
      const order = this.commomService.currentOrder;
      order.token = token;
  
      this.storeService.post_gnet_transaction(order).subscribe({
        next: (response) => {
          location.href = `${response.url}?token=${response.token}`;
          this.spinner.hide();
        },
        error: (response) => {
          this.spinner.hide();
        }
      });
    }, error => {
      this.spinner.hide();
    });
  }


  // guardar() {
  //   if (this.validate()) {
  //     this.flow();
  //   }
  // }

  guardar() {
    if (this.validate()) {
      this.modalService.open(this.paymentModal, { centered: true });
    }
  }

  cotizacion() {
    if (this.validate()) {
      this.spinner.show();
      this.reCaptchaV3Service.execute('contact').subscribe((token: string) => {
        const order = this.commomService.currentOrder;
        order.token = token;

        this.storeService.post_cotizacion(order).subscribe({
          next: (e) => {
            this.spinner.hide();
            this.showAlert();
          },
          error: (response) => {
            this.spinner.hide();
          }
        });
      }, error => {
        this.spinner.hide();
      });
    }
  }


  countTruck(i: number) {
    return Array(Math.ceil(i / 7.5));
  }

  showFacturacionAddressAlert() {
    this.form?.get('address')?.setValue('');
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = 'dirección facturación';
    modalRef.componentInstance.description = 'Su dirección de facturación es incompleta. Asegure seleccionar una de las opciones disponibles';
  }

  showAlert() {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = 'cotización enviada';
    modalRef.componentInstance.description = 'Su cotización será enviada a su correo.';
    modalRef.componentInstance.function = () => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('');
    };
  }

  cotizacionAlert() {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = 'cotización';
    modalRef.componentInstance.description = 'Su cotización se encuentra vencida o es incorrecta.';
    modalRef.componentInstance.function = () => {
      this.modalService.dismissAll();
      this.router.navigateByUrl('');
    };
  }

  checkAddress(place: any) {
    let types = place.types.filter((x: string) => x !== "plus_code" && x !== "route");
    this.form?.get('helper')?.setValue('');
    this.form?.get('address')?.setValue('');
    if (types.length > 0) {
      let valid = 0;
      place.address_components.forEach((x: { types: string[] }) => {
        if (x.types.includes('administrative_area_level_1')) {
          valid += 1;
        }
        if (x.types.includes('administrative_area_level_3')) {
          valid += 1;
        }
        if (x.types.includes('route')) {
          valid += 1;
        }
        if (x.types.includes('street_number')) {
          valid += 1;
        }
      });
      if (valid === 4) {
        this.form?.get('helper')?.setValue(place.formatted_address);
        this.form?.get('address')?.setValue(place.formatted_address);
        return;
      }
    }
    this.form?.get('helper')?.setErrors({ noValid: true });
  }

  guardarAddress() {
    let formValue = this.formAddress?.value;

    let request =
    {
      address: `${formValue.street} ${formValue.number}, ${formValue.comuna} ${formValue.region}`
    };

    this.geocoder?.geocode(request, (results: any, status: any) => {
      if (status === google.maps.GeocoderStatus.OK) {
        let result = results[0];
        this.checkAddress(result);
      } else {
        this.form?.get('address')?.setValue('');
        this.form?.get('helper')?.setValue('');
        this.form?.get('helper')?.setErrors({ noValid: true });
      }
      this.modalService.dismissAll();
    });
  }

  openModal(content: any) {
    this.modalService.open(content, { centered: true, backdrop: true, size: 'lg' });
  }
}
