import { Comuna } from './../models/comuna';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Plant, PlantAdmin } from '../models/plant';
import { ScheduleRestrict, Schedule, ScheduleAdmin } from '../models/schedule';
import { Product, Category } from '../models/product';
import { Order } from '../models/order';
import { Dispatch, DispatchAdmin } from '../models/dispatch';
import { Contact } from '../models/contact';
import { Setting } from '../models/setting';
import { Shift } from '../models/shift';
import { Executive } from '../models/executive';
import { ConfigService } from '../shared/utils/http';
import { Region } from '../models/region';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  http: ConfigService;

  constructor(
    private readonly client: HttpClient,
    private readonly router: Router) {
    this.http = new ConfigService(client);
  }

  get_plant_actives() {
    return this.http.get<Plant[]>('plant/activas/');
  }

  get_schedule_restriction(day: number) {
    return this.http.get<ScheduleRestrict>(`settings/schedule/${day}/`);
  }

  get_products_category(): Observable<Category[]> {
    return this.http.get<any[]>(`product/category/`)
      .pipe(
        map(data => {
          let categories: Category[] = [];
          data.forEach((i, v) => {
            const cat: Category = {
              id: i.general_use,
              name: i.general_use_name,
              sub_category: []
            };
            let exist = categories.find(c => c.id === i.general_use);
            if (exist) {
              exist.sub_category.push({
                id: i.detail_use,
                name: i.detail_use_name,
                sub_category: []
              });
            } else {
              cat.sub_category = [
                {
                  id: i.detail_use,
                  name: i.detail_use_name,
                  sub_category: []
                }
              ]
              categories.push(cat);
            }
          });
          return categories;
        })
      );
  }

  get_products_by_category(category: number, sub_category: number) {
    return this.http.get<Product[]>(`product/category/${category}/${sub_category}/`);
  }

  get_dispatch(id: number) {
    return this.http.get<Dispatch>(`dispatch/${id}/result/`);
  }

  get_cotizacion(id: string) {
    return this.http.get<Order>(`cotizacion/${id}/recover/`);
  }

  get_schedules(): Observable<ScheduleAdmin[]> {
    return this.http.get<ScheduleAdmin[]>(`schedules/`);
  }

  get_cotizacions(): Observable<DispatchAdmin[]> {
    return this.http.get<DispatchAdmin[]>(`cotizacion/`);
  }

  get_dispatchs(): Observable<DispatchAdmin[]> {
    return this.http.get<DispatchAdmin[]>(`dispatch/`);
  }

  get_contacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(`help/`);
  }

  get_settings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`settings/`);
  }

  get_day_shifts(): Observable<Shift[]> {
    return this.http.get<Shift[]>(`shifts`);
  }

  get_plants(): Observable<PlantAdmin[]> {
    return this.http.get<PlantAdmin[]>('plant/');
  }
  get_plants_all(): Observable<PlantAdmin[]> {
    return this.http.get<PlantAdmin[]>('plant/all');
  }

  get_comunas(): Observable<Comuna[]> {
    return this.http.get<Comuna[]>('comuna/');
  }
  get_comunas_all(): Observable<Comuna[]> {
    return this.http.get<Comuna[]>('comuna/all');
  }

  get_regions(): Observable<Region[]> {
    return this.http.get<Region[]>('region/');
  }

  get_executives(): Observable<Executive[]> {
    return this.http.get<Executive[]>('executive/');
  }
  // #POST

  post_schedule_options(plantId: number, productId: number, amount: number, day: string, fine: number) {
    const data = {
      plant: plantId,
      product: productId,
      amount: amount,
      day: day,
      fine:fine
    }

    return this.http.post<Schedule[]>(`schedules/available/`, data);
  }

  post_webpay_transaction(order: Order) {
    return this.http.post<any>(`webpay/init/`, order);
  }

  post_flow_transaction(order: Order) {
    return this.http.post<any>(`flow/init/`, order);
  }

  post_gnet_transaction(order: Order) {
    return this.http.post<any>('gnet/init/', order);
  }

  post_cotizacion(order: Order) {
    return this.http.post<any>(`cotizacion/save/`, order);
  }

  post_help_contact(data: any) {
    return this.http.post<any>(`help/contact/`, data);
  }

  post_import_schedule(data: any[]) {
    return this.http.post<any>(`schedules/import/`, data);
  }

  // PUT
  put_settings(data: Setting) {
    return this.http.put<any>(`settings/1/`, data);
  }

  put_plants(data: Setting) {
    return this.http.put<any>(`plant/1/`, data);
  }

  put_shifts(data: Shift) {
    return this.http.put<any>(`shifts/1/`, data);
  }

  put_executives(data: Executive) {
    return this.http.put<any>('executive/1/', data);
  }

  put_comunas(data: Comuna) {
    return this.http.put<any>('comuna/1/', data);
  }

  put_regions(data: Region) {
    return this.http.put<any>('region/1/', data);
  }

  put_sap_retry(id: any) {
    return this.http.put<any>(`sap/${id}/`, {});
  }


  delete_executives(data: number) {
    return this.http.delete(`executive/${data}/`);
  }

  create_user_dispatch(data: Dispatch) {
    return this.http.post<Order>(`cotizacion/register_user/`, data);
  }

  get_comuna_planta_active() {
    return this.http.get<any[]>(`comuna_planta/actives/`);
  }
  put_comuna_planta(data: Setting) {
    return this.http.put<any>(`comuna_planta/${data.id}/`, data);
  }
  pot_comuna_planta(data: Setting) {
    return this.http.post<any>(`comuna_planta/`, data);
  }
  create_update(data: any) {
    if (data.id) {
      return this.put_comuna_planta(data);
    }
    return this.pot_comuna_planta(data);
  }
  post_payment_confirmation(data: any) {
    return this.http.post<any>(`gnet/confirmation/`, data);
  }
  
  post_payment_cancellation(data: any) {
    return this.http.post<any>(`gnet/cancel/`, data);
  }

}

